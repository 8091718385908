<template>
  <div class="sld_quick_reply">
    <div class="top_header">
      <p class="header_title">{{ L['快捷回复'] }}</p>
      <button @click="openAdd">{{ L['添加快捷回复语'] }}</button>
    </div>
    <el-table :data="quickReplyList.list" :row-class-name="tableRowClassName">
      <el-table-column type="index" width="220" :label="L['序号']">
      </el-table-column>
      <el-table-column prop="msgContent" :label="L['快捷回复语']" width="680">
      </el-table-column>

      <el-table-column prop="sort" :label="L['排序']" width="250">
      </el-table-column>
      <el-table-column :label="L['显示']" width="250">
        <template #default="scope">
          <div class="switch_wrap" @click="operateContent(1, scope.row)">
            <el-switch v-model="scope.row.isShow"></el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="L['操作']" width="300">
        <template #default="scope">
          <button @click="openEdit(scope.row)">{{ L['编辑'] }}</button>
          <el-popconfirm
            :title="L['确定删除此内容吗？']"
            @confirm="operateContent(0, scope.row)"
            :confirmButtonText="L['确定']"
            :cancelButtonText="L['取消']"
          >
            <template #reference>
              <button>{{ L['删除'] }}</button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="empty_quick"
      v-if="!quickReplyList.list.length > 0 && firstLoading"
    >
      <img src="@/assets/goods/empty_data.png" alt="" />
      <p>{{ L['暂无快捷回复内容~'] }}</p>
    </div>

    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="30%"
      lock-scroll="false"
      top="25vh"
      @closed="dialogClosed"
    >
      <table id="popTable">
        <tr class="row1">
          <td class="title" valign="top">
            <i>*</i><span>{{ L['快捷回复语'] }}：</span>
          </td>
          <td>
            <el-input
              type="textarea"
              :rows="4"
              :placeholder="L['请输入快捷回复语内容']"
              v-model="quickReplyContent"
              resize="none"
              maxlength="50"
              show-word-limit
              @input="watchContent(0)"
            >
            </el-input>
            <span v-show="contentMessage" class="message">{{
              contentMessage
            }}</span>
          </td>
        </tr>
        <tr class="row2">
          <td></td>
          <td class="tip">
            <span>{{ L['最多输入50字'] }}</span>
          </td>
        </tr>
        <tr class="row3">
          <td class="title" valign="top">
            <i>*</i><span>{{ L['输入排序'] }}：</span>
          </td>
          <td>
            <el-input
              :placeholder="L['请输入排序']"
              v-model="replySort"
              @input="watchContent(1)"
              maxlength="3"
              show-word-limit
            ></el-input>
            <span v-show="sortMessage" class="message">{{ sortMessage }}</span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="tip">
            <span>{{ L['请输入0~255的数字，数值越小，显示越靠前'] }}</span>
          </td>
        </tr>
        <tr class="row4">
          <td style="text-align: right;">
            <span>{{ L['显示'] }}：</span>
          </td>
          <td>
            <el-switch v-model="switchFlag"></el-switch>
          </td>
        </tr>
      </table>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ L['取 消'] }}</el-button>
          <el-button type="primary" @click="confirm">{{
            L['确 定']
          }}</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="sld_pagination">
      <el-pagination
        @prev-click="handlePrevCilickChange"
        @next-click="handleNextCilickChange"
        @current-change="handleCurrentChange"
        :currentPage="page.current"
        :page-size="page.pageSize"
        layout="prev, pager, next, jumper"
        :total="page.total"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  name: 'quickReply',
  setup() {
    const msgId = ref('') //全局内容ID
    const addOrEdit = ref(0)
    const quickReplyContent = ref('') //快捷回复语内容
    const replySort = ref('') //回复语排序
    const dialogVisible = ref(false) //对话框显示flag
    const page = reactive({
      //页数
      current: '',
      pageSize: '',
      total: ''
    })
    const dialogTitle = ref('')
    const quickReplyList = reactive({ list: [] }) //快捷回复语列表
    const params = reactive({ current: 1 })
    const switchFlag = ref(false)
    const { proxy } = getCurrentInstance()
    const contentMessage = ref('')
    const sortMessage = ref('')
    const firstLoading = ref(false)
    const L = proxy.$getCurLanguage()
    const isPreventClick = ref(false)
    const identity = localStorage.getItem('identity')

    //获取快捷回复语
    const getQuickReplyList = () => {
      proxy.$get(`v3/helpdesk/${identity}/quick/list`, params).then(res => {
        if (res.state == 200) {
          quickReplyList.list = res.data.list
          page.pageSize = res.data.pagination.pageSize
          page.total = res.data.pagination.total
          page.current = res.data.pagination.current
          quickReplyList.list.map(item => {
            item.isShow = item.isShow ? true : false
          })
          firstLoading.value = true
        }
      })
    }

    //页数改变方法--start
    const handlePrevCilickChange = e => {
      params.current = e
      getQuickReplyList()
    }
    const handleNextCilickChange = e => {
      params.current = e
      getQuickReplyList()
    }
    const handleCurrentChange = e => {
      params.current = e
      getQuickReplyList()
    }

    //确认start
    const confirm = () => {
      if (isPreventClick.value) {
        return
      }
      isPreventClick.value = true

      let num = /^[0-9]+[0-9]*]*$/
      if (!quickReplyContent.value) {
        contentMessage.value = L['不能为空']
        isPreventClick.value = false
        return
      } else if (
        Number(replySort.value) > 255 ||
        Number(replySort.value) < 0 ||
        !num.test(replySort.value)
      ) {
        sortMessage.value = L['值应为0~255的整数值']
        isPreventClick.value = false
        return
      } else if (!replySort.value.toString()) {
        sortMessage.value = L['排序值不能为空']
        isPreventClick.value = false
        return
      }

      let isShow = switchFlag.value ? 1 : 0

      if (addOrEdit.value == 0) {
        let params = {
          msgContent: quickReplyContent.value,
          sort: replySort.value,
          isShow: isShow
        }
        proxy.$post(`v3/helpdesk/${identity}/quick/add`, params).then(res => {
          if (res.state == 200) {
            ElMessage.success(res.msg)
            getQuickReplyList()
            dialogVisible.value = false
          } else {
            ElMessage(res.msg)
            isPreventClick.value = false
          }
        })
      } else if (addOrEdit.value == 1) {
        let params = {
          quickMsgId: msgId.value,
          isShow: isShow,
          msgContent: quickReplyContent.value,
          sort: replySort.value
        }
        proxy
          .$post(`v3/helpdesk/${identity}/quick/update`, params)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg)
              getQuickReplyList()
              dialogVisible.value = false
            } else {
              ElMessage(res.msg)
              isPreventClick.value = false
            }
          })
      }
    }
    //确认end

    const watchContent = type => {
      if (type == 0 && quickReplyContent.value) {
        contentMessage.value = ''
      } else if (type == 1 && replySort.value && replySort.value < 255) {
        sortMessage.value = ''
      }
    }

    //改变开关或者删除start
    const operateContent = (type, quickItem) => {
      quickItem.isShow = !quickItem.isShow
      let isShowFlag = !quickItem.isShow ? 1 : 0
      let params = {
        quickMsgId: quickItem.quickMsgId
      }
      if (type == 1) {
        params.isShow = isShowFlag
        proxy
          .$post(`v3/helpdesk/${identity}/quick/isShow`, params)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg)
              getQuickReplyList()
            } else {
              ElMessage.success(res.msg)
            }
          })
      } else if (type == 0) {
        proxy
          .$post(`v3/helpdesk/${identity}/quick/delete`, params)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg)
              getQuickReplyList()
            } else {
              ElMessage.success(res.msg)
            }
          })
      }
    }
    //改变开关或者删除end

    //打开对话框（编辑内容）
    const openEdit = item => {
      dialogTitle.value = L['编辑快捷语回复']
      addOrEdit.value = 1
      dialogVisible.value = true
      msgId.value = item.quickMsgId
      quickReplyContent.value = item.msgContent
      replySort.value = item.sort
      switchFlag.value = item.isShow
      isPreventClick.value = false
    }

    //打开对话框（添加内容）
    const openAdd = () => {
      quickReplyContent.value = ''
      replySort.value = ''
      switchFlag.value = false
      dialogTitle.value = L['添加快捷语回复']
      addOrEdit.value = 0
      dialogVisible.value = true
      isPreventClick.value = false
    }

    const dialogClosed = () => {
      isPreventClick.value = false
    }

    onMounted(() => {
      getQuickReplyList()
    })

    return {
      dialogVisible,
      quickReplyContent,
      replySort,
      quickReplyList,
      page,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      switchFlag,
      confirm,
      operateContent,
      openEdit,
      openAdd,
      dialogTitle,
      sortMessage,
      watchContent,
      contentMessage,
      firstLoading,
      L,
      dialogClosed
    }
  }
}
</script>

<style lang="scss">
.el-table th > .cell {
  text-align: center;
}

.el-table .cell {
  display: flex;
  justify-content: center;
}

.el-table__empty-block {
  display: none;
}

button {
  padding: 4px 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 13px;
  margin-left: 10px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;

  &:hover {
    border: 1px solid $colorMain;
    color: $colorMain;
  }
}

.sld_quick_reply {
  .top_header {
    width: 100%;
    height: 59px;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    .header_title {
      padding-left: 10px;
      border-left: 4px solid $colorMain;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    button {
      color: #fff;
      padding: 11px;
      background-color: $colorMain;
      border-radius: 3px;
      border: none;
      margin-right: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
    }
  }

  .top_bar {
    padding: 13px 0;
    background-color: #eee;
    display: flex;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    .number_title {
      width: 210px;
      text-align: center;
      /* padding: 0 90px; */
    }

    .reply_title {
      width: 455px;
      /* padding: 0 190px; */
      text-align: center;
    }

    .sort_title {
      width: 310px;
      /* padding: 0 140px; */
      text-align: center;
    }

    .default_use_title {
      width: 338px;
      text-align: center;
      /* padding: 0 154px; */
    }

    .operate_title {
      width: 310px;
      /* padding: 0 140px; */
      text-align: center;
    }
  }

  .container {
    height: 710px;

    .con_item {
      width: 99%;
      display: flex;
      height: 50px;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;

      .number {
        width: 210px;
        text-align: center;
      }

      .sort {
        text-align: center;
        width: 310px;
      }

      .default_use {
        padding: 0 150px;
      }

      .operate {
        width: 310px;
        display: flex;
        justify-content: center;

        button {
          padding: 4px 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 13px;
          margin-left: 10px;
          border-radius: 4px;
          background: #fff;
          border: 1px solid #dcdfe6;
          color: #606266;

          &:hover {
            border: 1px solid $colorMain;
            color: $colorMain;
          }
        }
      }

      .content {
        width: 455px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .sld_pagination {
    margin-top: 20px;
  }

  .el-dialog {
    border-radius: 10px;
  }

  .el-dialog__header {
    background: #409eff;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    height: 40px;
    padding: 11px 20px 10px;
  }

  .el-dialog__headerbtn {
    top: 13px;
  }

  .el-dialog__title {
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }

  .el-button {
    padding: 7px 19px;
  }

  #popTable {
    margin: 0 auto;
    border-spacing: 10px;

    .title {
      text-align: right;
    }

    .tip {
      color: #c0c4cc;
    }

    i {
      color: #e2231a;
      font-style: normal;
    }

    .message {
      font-size: 12px;
      line-height: 2;
      padding-top: 4px;
      color: #e2231a;
      font-family: Microsoft YaHei;
    }

    td {
      max-width: 284px;

      &.title {
        padding-top: 9px;
      }
    }
  }
}

.empty_quick {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;

  p {
    color: #999999;
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
